import ContactLayout from 'pages/layout/ContactLayout';
import MainLayout from 'pages/layout/MainLayout';
import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

const HomePage = lazy(() => import('pages/home/HomePage'));
const AboutPage = lazy(() => import('pages/about/AboutPage'));
const SolutionsPage = lazy(() => import('pages/solutions/SolutionsPage'));
const ContactPage = lazy(() => import('pages/contact/ContactPage'));

const appRouter = createBrowserRouter([
    {
        element: <MainLayout />,
        // errorElement: <ErrorPage />,
        children: [
            {
                path: '/',
                element: <HomePage />,
            },
            {
                path: '/about',
                element: <AboutPage />,
            },
            {
                path: '/solutions',
                element: <SolutionsPage />,
            },
        ],
    },
    {
        path: '/MGT7A',
        element: (
            <iframe
                src='/MGT7A.pdf'
                style={{ width: '100%', height: '100vh' }}
                title='Terms and Conditions'
            />
        ),
    },
    {
        element: <ContactLayout />,
        children: [
            {
                path: '/contact',
                element: <ContactPage />,
            },
        ],
    },
]);
export { appRouter };
