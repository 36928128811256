import bg from 'assets/images/bg.jpg';

import customer_1 from 'assets/images/customers/customer_1.png';
import customer_2 from 'assets/images/customers/customer_2.png';
import customer_3 from 'assets/images/customers/customer_3.png';
import customer_4 from 'assets/images/customers/customer_4.png';
import customer_5 from 'assets/images/customers/customer_5.png';
import customer_6 from 'assets/images/customers/customer_6.png';
import customer_7 from 'assets/images/customers/customer_7.png';
import customer_8 from 'assets/images/customers/customer_8.png';
import customer_9 from 'assets/images/customers/customer_9.png';
import customer_10 from 'assets/images/customers/customer_10.png';
import customer_11 from 'assets/images/customers/customer_11.png';
import customer_12 from 'assets/images/customers/customer_12.png';
import customer_13 from 'assets/images/customers/customer_13.png';
import customer_14 from 'assets/images/customers/customer_14.png';
import customer_15 from 'assets/images/customers/customer_15.png';
import customer_16 from 'assets/images/customers/customer_16.png';
import customer_17 from 'assets/images/customers/customer_17.png';
import customer_18 from 'assets/images/customers/customer_18.png';
import customer_19 from 'assets/images/customers/customer_19.png';
import customer_20 from 'assets/images/customers/customer_20.png';
import customer_21 from 'assets/images/customers/customer_21.png';
import customer_22 from 'assets/images/customers/customer_22.png';
import customer_23 from 'assets/images/customers/customer_23.png';
import customer_24 from 'assets/images/customers/customer_24.png';

import smartCity from 'assets/images/smartcity.png';
import industry from 'assets/images/industries.png';
import commercial from 'assets/images/commercial.png';
import logoDark from 'assets/images/logo_dark.png';
import logoLight from 'assets/images/logo_light.png';
import logoDrop from 'assets/images/logo_drop.png';
import enter from 'assets/images/enter.svg';
import aquagenLogo from 'assets/images/aquagen_logo.png';
import ribbon1 from 'assets/images/ribbon_1.png';
import industry1 from 'assets/lottie/industry_1.json';
import industry2 from 'assets/lottie/industry_2.json';
import industry3 from 'assets/lottie/industry_3.json';

import complianceFssai from 'assets/images/compliances/compliance_fssai.png';
import complianceBrsr from 'assets/images/compliances/compliance_brsr.png';
import complianceCgwa from 'assets/images/compliances/compliance_cgwa.png';
import complianceCpcb from 'assets/images/compliances/compliance_cpcb.png';
import complianceBreeam from 'assets/images/compliances/compliance_breeam.png';
import complianceEurapean from 'assets/images/compliances/compliance_european.png';
import complianceCsrd from 'assets/images/compliances/compliance_csrd.png';
import complianceCdp from 'assets/images/compliances/compliance_cdp.png';
import complianceZld from 'assets/images/compliances/compliance_zld.png';

import certificationLeed from 'assets/images/certificates/certification_leed.png';
import certificationIso from 'assets/images/certificates/certification_iso.png';
import certificationGreenCo from 'assets/images/certificates/certification_green_co.png';
import certificationIgbc from 'assets/images/certificates/certification_igbc.png';
import certificationStewardShip from 'assets/images/certificates/certification_stewardship.png';
import certificationGri from 'assets/images/certificates/certification_gri.png';
import certificationHighIndex from 'assets/images/certificates/certification_high_index.png';
import certificationWaterSense from 'assets/images/certificates/certification_water_sense.png';

import dashboard from 'assets/images/dashboard.png';
import solutionHero1 from 'assets/images/solutionPage/solution_hero_1.jpg';
import solutionHero2 from 'assets/images/solutionPage/solution_hero_2.jpg';
import solutionHero3 from 'assets/images/solutionPage/solution_hero_3.jpg';
import solutionHero4 from 'assets/images/solutionPage/solution_hero_4.jpg';

import solutionDairy from 'assets/images/solutionPage/solution_dairy.jpg';
import solutionHospital from 'assets/images/solutionPage/solution_hospital.jpg';
import solutionMetal from 'assets/images/solutionPage/solution_metal.jpg';
import solutionOffice from 'assets/images/solutionPage/solution_office.jpg';
import solutionTextile from 'assets/images/solutionPage/solution_textile.jpg';

import microsoftMention from 'assets/images/mentionSection/microsoft-mention.jpg';
import modiMention from 'assets/images/mentionSection/modi-mention.jpg';
import marthaMention from 'assets/images/mentionSection/st-marths-mention.jpg';
import tataSteelMention from 'assets/images/mentionSection/tata-steel-mention.jpg';
import aquarelleMention from 'assets/images/mentionSection/aquarelle-mention.jpg';
import copMention from 'assets/images/mentionSection/cop-mention.jpg';
import eepiMention from 'assets/images/mentionSection/eepi-winner-mention.jpg';
import kpmgMention from 'assets/images/mentionSection/kpmg-mention.jpg';
import nasscomMention from 'assets/images/mentionSection/nasscom-mention.jpg';
import norrskenMention from 'assets/images/mentionSection/norrsken-mention.jpg';

import vt1 from 'assets/images/thumbnails/videos/1.jpg';
import vt2 from 'assets/images/thumbnails/videos/2.jpg';
import vt3 from 'assets/images/thumbnails/videos/3.jpg';
import vt4 from 'assets/images/thumbnails/videos/4.jpg';
import vt5 from 'assets/images/thumbnails/videos/5.jpg';
import vt6 from 'assets/images/thumbnails/videos/6.jpg';
import vt7 from 'assets/images/thumbnails/videos/7.jpg';
import vt8 from 'assets/images/thumbnails/videos/8.jpg';
import vt9 from 'assets/images/thumbnails/videos/9.jpg';
import vt10 from 'assets/images/thumbnails/videos/10.jpg';
import vt11 from 'assets/images/thumbnails/videos/11.jpg';
import vt12 from 'assets/images/thumbnails/videos/12.jpg';
import vt13 from 'assets/images/thumbnails/videos/13.jpg';
import vt14 from 'assets/images/thumbnails/videos/14.jpg';
import vt15 from 'assets/images/thumbnails/videos/15.jpg';

import articleBusinessStandard from 'assets/images/thumbnails/articles/article_business_standard.jpg';
import articleBusinessLine from 'assets/images/thumbnails/articles/article_business_line.jpg';
import articleEtInfra from 'assets/images/thumbnails/articles/article_et_infra.jpg';
import articleForbes from 'assets/images/thumbnails/articles/article_forbes.webp';
import articleIndiaAi from 'assets/images/thumbnails/articles/article_india_ai.jpg';
import articleKpmg from 'assets/images/thumbnails/articles/article_kpmg.jpg';
import articleSifted from 'assets/images/thumbnails/articles/article_sifted.jpg';
import articleNdtv from 'assets/images/thumbnails/articles/article_ndtv.jpg';
import articleStory from 'assets/images/thumbnails/articles/article_story.jpg';
import articleTata from 'assets/images/thumbnails/articles/article_tata.jpg';
import articleWwa from 'assets/images/thumbnails/articles/article_wwa.jpg';

class Assets {
    static readonly images = {
        bg: bg,
        logoDark: logoDark,
        logoLight: logoLight,
        logoDrop: logoDrop,
        aquagenLogo: aquagenLogo,
        ribbon1: ribbon1,
        industry: industry,
        commercial: commercial,
        smartCity: smartCity,
        enter: enter,
        dashboard: dashboard,

        customers: [
            customer_1,
            customer_2,
            customer_3,
            customer_4,
            customer_5,
            customer_6,
            customer_7,
            customer_8,
            customer_9,
            customer_10,
            customer_11,
            customer_12,
            customer_13,
            customer_14,
            customer_15,
            customer_16,
            customer_17,
            customer_18,
            customer_19,
            customer_20,
        ],

        testimonials: {
            lAndTLogo: customer_2,
            tataSteelLogo: customer_11,
            aquarelleLogo: customer_13,
            britaniaLogo: customer_21,
            elcitaLogo: customer_22,
            gokulLogo: customer_23,
            dairyLogo: customer_24,
        },

        certificates: {
            certificationGreenCo: certificationGreenCo,
            certificationLeed: certificationLeed,
            certificationIso: certificationIso,
            certificationIgbc: certificationIgbc,
            certificationGri: certificationGri,
            certificationStewardShip: certificationStewardShip,
            certificationHighIndex: certificationHighIndex,
            certificationWaterSense: certificationWaterSense,
        },

        compliance: {
            complianceFssai: complianceFssai,
            complianceBrsr: complianceBrsr,
            complianceCpcb: complianceCpcb,
            complianceCgwa: complianceCgwa,
            complianceEurapean: complianceEurapean,
            complianceBreeam: complianceBreeam,
            complianceCdp: complianceCdp,
            complianceCsrd: complianceCsrd,
            complianceZld: complianceZld,
        },

        solutionHero: {
            solutionHero1: solutionHero1,
            solutionHero2: solutionHero2,
            solutionHero3: solutionHero3,
            solutionHero4: solutionHero4,
        },

        solutionSuccessStories: {
            solutionDairy: solutionDairy,
            solutionHospital: solutionHospital,
            solutionMetal: solutionMetal,
            solutionOffice: solutionOffice,
            solutionTextile: solutionTextile,
        },

        mentionSection: {
            microsoftMention: microsoftMention,
            modiMention: modiMention,
            tataSteelMention: tataSteelMention,
            marthaMention: marthaMention,
            aquarelleMention: aquarelleMention,
            copMention: copMention,
            eepiMention: eepiMention,
            kpmgMention: kpmgMention,
            nasscomMention: nasscomMention,
            norrskenMention: norrskenMention,
        },

        thumbnails: {
            videos: {
                vt1: vt1,
                vt2: vt2,
                vt3: vt3,
                vt4: vt4,
                vt5: vt5,
                vt6: vt6,
                vt7: vt7,
                vt8: vt8,
                vt9: vt9,
                vt10: vt10,
                vt11: vt11,
                vt12: vt12,
                vt13: vt13,
                vt14: vt14,
                vt15: vt15,
            },
            articles: {
                articleBusinessLine: articleBusinessLine,
                articleBusinessStandard: articleBusinessStandard,
                articleKpmg: articleKpmg,
                articleSifted: articleSifted,
                articleNdtv: articleNdtv,
                articleTata: articleTata,
                articleIndiaAi: articleIndiaAi,
                articleForbes: articleForbes,
                articleStory: articleStory,
                articleEtInfra: articleEtInfra,
                articleWwa: articleWwa,
            },
        },
    };

    static readonly lottie = {
        industry1: industry1,
        industry2: industry2,
        industry3: industry3,
    };

    static readonly videos = {};

    static readonly colors = {
        primary: '#00374A',
        primaryAccent: '#2a7495',
        secondary: '#43BFEB',
        accent: '#82D1ED',
        fontBlue: '#6a8aa5',
        lightAccent: '#F6FCFD',
        divider: '#CBCBCB',
        disabled: '#d3d3d3',
        disabledLight: '#F1F1F1',
        darkSeaGreen: '#1D5D60',
        seaGreen: '#37b6bb',
        seaGreenAccent: '#40F8FF',
        red: '#DB6447',
        white: '#F8FAFC',
        black: '#000000',
    };
}

export default Assets;
